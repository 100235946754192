import {on, closest, findAllIn, findIn, setAttribute, removeAttribute} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-conditional-select', function (input) {
        on('change', function(evt) {
            let value = input.value;
            let container = closest('.js-conditinal-select__wrapper', input);

            if (container) {
                findAllIn('.js-conditional-sub-select', container).map(function (item) {
                    item.value = '';
                    setAttribute('hidden', true, closest('.form-group', item));
                });

                if (findIn(`select[data-groupname="${value}"]`, container)) {
                    removeAttribute('hidden', closest('.form-group', findIn(`select[data-groupname="${value}"]`, container)));
                }
            }
        }, input);
    });
}