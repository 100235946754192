"use strict";
import {closest, on, setAttribute, trigger, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import * as formValidation from '@elements/form-validation';

export function init() {
    onFind('.js-input-autosubmit', function (element) {
        let form = closest('.js-form', element);

        formValidation.getApi(form).then((formApi) => {
            let formInstance = formApi.getFormInstance();

            on('change', (e) => {
                formInstance.destroy();
                findIn('.js-form__content', form).insertAdjacentHTML('beforeend', '<input value="1" name="input-autosubmitted" type="hidden" />');

                setAttribute('action', form.getAttribute('data-action'), form);
                trigger('form-validation.submit', form);
            }, element);
        });
    });
}