import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import Tabs from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tabs(element);
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element, {
        toggle: false
    });
});

import * as lightbox from '@elements/lightbox';
lightbox.init({
    plugins: ['video'],
    mobileSettings: {
        controls: true,
        showCloseIcon: true
    }
});

import * as video from './scripts/video';
video.init();

import * as localizedVideo from './scripts/localized-video';
localizedVideo.init();

import * as dropdownSubmenu from './scripts/dropdown-submenu';
dropdownSubmenu.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item flex-shrink-0"><a class="nav-link js-smoothscroll__link anchor-nav__link" href="#' + anchor + '"><span class="icon icon-chevron anchor-nav__link-icon"></span>'+ text + '</span></a></li>';
        },
        smoothScroll: false,
        scrollSpy: !!document.getElementsByClassName('js-anchor-nav__item'),
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: matchMedia('(max-width: 767px)').matches ? 152 : 200
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: matchMedia('(max-width: 767px)').matches ? 152 : 190, initialPosition: true});

import * as downloadList from './scripts/download-list';
downloadList.init();

import * as anchorNavSticky from './scripts/anchor-nav-sticky';
anchorNavSticky.init();

import * as googleMap from './scripts/google-map';
googleMap.init();

import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as brandSlider from './scripts/brand-slider';
if(_config.brandSlider === true){
    brandSlider.init();
}

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as formValidationAutoSubmit from './scripts/form-validation-auto-submit';
formValidationAutoSubmit.init();

import * as conditionalSelect from './scripts/conditional-select';
conditionalSelect.init();