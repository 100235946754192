import ScrollSpy from 'bootstrap/js/dist/scrollspy';
import {findIn, findAllIn, on, find} from "@elements/dom-utils";
import initModulesInScope, {onFind} from "@elements/init-modules-in-scope";

const selectors = {
    list: '.js-download-list__nav-list',
    item: '.js-download-list__item'
};

export function init () {
    onFind('.js-download-list',function (container) {
        let element = findIn('.js-download-list__nav', container),
            list = findIn('.js-download-list__nav-list', element);

        let listItem = findAllIn(selectors.item, container);

        listItem.forEach((listItem) => {
            let title = listItem.dataset.downloadAnchorNavTitle,
                titleForAnchor = title.replace(/[^a-z]/gi, '').toLowerCase();

            if(listItem.getAttribute('id')){
                addNavItem(renderItem, list,title,listItem.getAttribute('id'));
            }else{
                listItem.setAttribute('id', 'anchor-' + titleForAnchor);
                addNavItem(renderItem, list,title, 'anchor-' + titleForAnchor);
            }
        });

        findAllIn('.js-download-list__nav-link', list).map((item) => {
           on('click', (evt) => {
               evt.preventDefault();
               let target = find(item.getAttribute('href')),
                   offset = matchMedia('(max-width: 767px)').matches ? 152 : 196;

               window.scrollTo({
                   top: target.getBoundingClientRect().top - offset + window.scrollY,
                   behavior: 'smooth'
               });
           }, item);
        });

        new ScrollSpy(document.body, {
            target: element,
            offset: matchMedia('(max-width: 767px)').matches ? 156 : 200,
            smoothScroll: false
        });
    });
}

function addNavItem(renderItem, list, text, anchor) {
    list.insertAdjacentHTML('beforeend', renderItem(text, anchor));
    initModulesInScope(list.lastChild);

    if(findIn(`[href='#${anchor}']`,list).length < 1) {
        list.append(renderItem(text, anchor))
    }
}

function renderItem (text, anchor) {
    return '<li class="nav-item download-list__nav-item flex-shrink-0"><a class="nav-link js-download-list__nav-link download-list__nav-link" href="#' + anchor + '">'+ text + '</span></a></li>';
}